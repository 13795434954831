<template>
  <div class="terminos_container">
    <div class="content">
      <h1 class="common_title">Términos y Condiciones</h1>

      <h2>I. Relación Contractual.</h2>
      <p>Por medio del presente Contrato, se describen los términos y condiciones contractuales (los "Términos y Condiciones") que regulan el acceso o uso (el “Uso”) que usted (el “Usuario”) haga de la plataforma ubicada en https://tifi.com.mx/ (la “Plataforma”), sus aplicaciones, páginas web, contenido, productos y servicios (los “Servicios”) puestos a disposición por QOHOR Network, S.A. de C.V. (“QOHOR”), con nombre comercial TiFi. </p>
      <h2>1. QOHOR. </h2>
      <p>QOHOR es una sociedad constituida en los Estados Unidos Mexicanos con domicilio social en Avenida Paseo de la Reforma 369, piso MZ, Colonia Cuauhtémoc, alcaldía Cuauhtémoc, C.P. 06500, en la Ciudad de México, e inscrita en el Registro Público de Comercio de la Ciudad de México, bajo el folio mercantil electrónico N-2021021437.</p>

      <h2>2. Usuario. </h2>
      <p>El Usuario deberá contar con la capacidad legal necesaria para la celebración de cualquier acto jurídico con QOHOR. QOHOR será capaz de implementar las acciones necesarias para verificar dicha capacidad legal. </p>
      <p>Al acceder y usar los Servicios el Usuario reconoce y acepta que: (i) QOHOR ha tomado medidas para evitar que se difunda información falsa o engañosa a través de la Plataforma y el uso de los Servicios; (ii) El Usuario garantiza y reconoce que no cuenta con sanciones económicas o imposibilidad para utilizar nuestros Servicios derivado de la aparición de su nombre en listas nacionales e internacionales, mencionando de manera enunciativa y no limitativa, la lista de OFAC (Office of Foreign Assets Control of the U.S. Treasury Department) o la lista de personas bloqueadas dada a conocer por la Unidad de Inteligencia Financiera en México de conformidad con las Resoluciones 1267 y 1373 emitidas por el Consejo de Seguridad de las Naciones Unidas; (iii) QOHOR da a conocer de forma clara e indubitable: (a) los criterios de selección de los Usuarios en QOHOR, así como la información y documentación que analiza para tales efectos y las actividades que realiza para verificar la veracidad de dicha información y documentación; y (b) el riesgo de los Usuarios, así como las metodologías para la evaluación y calificación de los mismos.</p>
      <p>A través del uso de TiFi (página web y app) el usuario garantiza la legitimidad de los datos proporcionados así como el uso de los mismos dentro de la plataforma y dentro de cualquier otra aplicación que coadyuve al cumplimiento y ofrecimiento de beneficios internos o externos. </p>

      <h2>3. Acuerdo. </h2>
      <p>Mediante el acceso a la Plataforma y uso de los Servicios, usted acuerda vincularse jurídicamente y establecer una relación contractual con QOHOR, la cual estará sujeta en todo momento a los Términos y Condiciones, así como a la normatividad aplicable en los Estados Unidos Mexicanos.</p>
      <p>En caso de que el Usuario o alguna otra persona no acepte los presentes Términos y Condiciones , los  cuales tienen carácter de obligatorios y vinculantes, deberá abstenerse de acceder o utilizar la Plataforma, Contenido y sus Servicios. </p>
      <p>Se podrán aplicar condiciones suplementarias a determinados Servicios, como la celebración de contratos adicionales o manifestaciones unilaterales de la voluntad que puedan ser necesarios o convenientes en términos de la regulación mexicana, por lo que dichas condiciones suplementarias se le comunicarán en relación con los Servicios aplicables. Las condiciones suplementarias se establecen además de las Condiciones, y se considerarán una parte de estas, para los fines de los Servicios aplicables. Las condiciones suplementarias prevalecerán sobre las Condiciones en el caso de conflicto con respecto a los Servicios aplicables.</p>

      <h2>4. Vigencia.</h2>
      <p>Los Términos y Condiciones permanecerán vigentes de manera indeterminada y desde el acceso del Usuario a la Plataforma.</p>

      <h2>5. Modificaciones</h2>
      <p>QOHOR puede terminar, cambiar, suspender o descontinuar cualquier aspecto de los Términos y Servicios en cualquier momento. Los cambios serán efectivos al siguiente día hábil de su publicación en la Plataforma. </p>
      <p>En caso de que el usuario continúe con el uso de los Servicios o acceso a la Plataforma, se entenderá que ha aceptado de manera tácita la modificación de los Términos y Condiciones. </p>

      <h2>6. Terminación.</h2>
      <p>QOHOR se reserva en todo momento la exclusión del Usuario de la Plataforma, así como a no proporcionarle los Servicios, incluyendo el caso en el cual el Usuario incumpla los Términos y Condiciones.</p>
      <p>La exclusión del Usuario de los Servicios o la Plataforma en ningún momento se realizará con base en prácticas de Discriminación en términos de lo contenido en la Ley Federal para Prevenir y Eliminar la Discriminación.  </p>

      <h2>7. Notificaciones.</h2>
      <p>El Usuario reconoce y acepta que QOHOR podrá realizar cualquier notificación relacionada con los Servicios mediante el uso del correo electrónico registrado en la Plataforma por el Usuario, así como mediante la Plataforma, Términos y Condiciones y aviso de privacidad de QOHOR. </p>

      <h2>8. Legislación aplicable.</h2>
      <p>Este Contrato se regirá e interpretará por las leyes aplicables de los Estados Unidos Mexicanos. </p>

      <h2>9. Jurisdicción.</h2>
      <p>Para la resolución de cualquier disputa, controversia, reclamación o diferencia que surja de o se relacione con el presente Contrato, las Partes se someten expresamente a la jurisdicción de los Tribunales de la Ciudad de México, renunciando a cualquier otro fuero presente o futuro que pudiera corresponderle en virtud de sus domicilios presentes o futuros o por cualquier otra causa.</p>

      <h2>10. Autonomía de Disposiciones.</h2>
      <p>La nulidad, invalidez, ilegalidad o cualquier vicio en cualquiera de las disposiciones de los presentes Términos y Condiciones, solo afectará a dicha disposición, y por lo tanto no afectará a las demás disposiciones aquí pactadas, las cuales conservarán su fuerza obligatoria.</p>

      <h2>11. Acuerdo Integro.</h2>
      <p>Los presentes Términos y Condiciones constituyen el acuerdo íntegro habido entre el Usuario y QOHOR en relación con la materia de este, y sustituye a todas las negociaciones, acuerdos y/o convenios llevados a cabo previa o simultáneamente a su firma.</p>

      <h2>II. Plataforma.</h2>
      <h2>12. Propiedad Intelectual.</h2>
      <p>La Plataforma, incluyendo sin limitación alguna todo el contenido (el “Contenido”) como son de forma enunciativa más no limitativa, textos, fotografías, ilustraciones, gráficos, videos, audio, diseños, códigos, datos y cualquier otra información son propiedad única y exclusiva de QOHOR, incluyendo pero no limitado a, cualesquiera derechos de autor, derechos de marca, derechos de patente, derechos de base de datos, derechos morales, y otras propiedades intelectuales y derechos patrimoniales del mismo, y se encuentran protegidos por la Ley Federal del Derecho de Autor, la Ley de la Propiedad Industrial, así como cualquier otro ordenamiento en materia de Propiedad Intelectual aplicable. </p>
      <p>El uso de los Servicios no le otorga en ningún momento al Usuario propiedad del Contenido, ni tampoco se le otorga licencia para explotación de estos, por lo que el Usuario reconoce que por ningún motivo adquiere algún derecho de propiedad al descargar algún material con derechos de autor relacionado con los Servicios. </p>
      <p>Los signos distintivos, incluyendo las marcas y avisos comerciales, así como demás Contenido, expuestos en los Servicios serán en todo momento propiedad de QOHOR, aún cuando los mismos se encuentren registrados o no, y no pueden ser usados por el Usuario sin consentimiento expreso y por escrito de QOHOR.</p>

      <h2>13. Licencia.</h2>
      <p>Sujeto al cumplimiento del Contrato, QOHOR le otorga una licencia limitada, no exclusiva, no sublicenciable, revocable, no transferible para: (i) el acceso y uso personal de los Servicios; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través de los Servicios, en cada caso solo para su uso personal, no comercial. QOHOR y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el presente.</p>

      <h2>14. Responsabilidad de los medios de acceso.</h2>
      <p>El Usuario será responsable en todo momento del uso que de los medios de acceso (los “Medios de Acceso”) proporcionados por QOHOR para su acceso a la Plataforma y los Servicios. </p>
      <p>El Usuario deberá mantener en secreto y abstenerse de revelar a terceros los Medios de Acceso, incluyendo, sin limitación, el nombre de usuario y clave de seguridad. El Usuario será responsable por todas las operaciones efectuadas en acceso o uso de los Servicios, pues el acceso a los mismos está restringido al ingreso y uso de su clave de seguridad, de conocimiento exclusivo del Usuario.</p>

      <h2>15. Proceso en caso de robo, extravío u olvido de los medios de acceso.</h2>
      <p>El Usuario notificará de inmediato a QOHOR por medio de la Plataforma. QOHOR le notificará al Usuario las medidas que deberá tomar. </p>

      <h2>16. Horarios de acceso a la Plataforma.</h2>
      <p>La Plataforma estará accesible las 24 horas del día, los 365 días del año. No obstante, lo anterior, el Usuario acuerda que podrán presentarse fallas o falta de acceso a la Plataforma y sus Servicios, caso en el cual el equipo de QOHOR hará sus mejores esfuerzos para reestablecerlos. </p>

      <h2>17. Promociones.</h2>
      <p>Las promociones que en su caso QOHOR ofrezca al Usuario las realizará por medio de la Plataforma o los Medios de Acceso del Usuario, ningún otro medio será válido para ofrecer promociones al Usuario. </p>

      <h2>18. Usuarios Fotosensibles.</h2>
      <p>Se hace del conocimiento del Usuario, que un muy pequeño porcentaje de personas pueden experimentar un ataque al ser expuestas a ciertas imágenes visuales, como luces o dibujos intermitentes que pueden aparecer en contenidos electrónicos o en línea. Aún personas que no tienen historia de ataques o de epilepsia pueden tener una condición no diagnosticada que puede causar estos "ataques epilépticos fotosensibles" al estar viendo dichos contenidos electrónicos. Estos ataques tienen una variedad de síntomas, incluyendo mareos, desorientación, confusión, pérdida momentánea de conciencia, crispación de ojos o cara, visión alterada o tirones o sacudidas de brazos o piernas. Por lo que, si el Usuario experimenta cualquiera de los síntomas anteriormente mencionados, o si el Usuario o su familia tiene historia de ataques, epilepsia o fotosensibilidad, QOHOR no se hará responsable de los daños que puedan causarse y el Usuario deberá de dejar inmediatamente de acceder y usar los Servicio.</p>

      <h2>19. Limitación de responsabilidad. </h2>
      <p>QOHOR no establece representación o garantía alguna sobre la exactitud, confiabilidad o integridad del contenido (texto e imágenes) de este sitio. Dentro de las limitantes establecidas por la ley, QOHOR renuncia a toda garantía, explícita o implícita, incluyendo sin limitación las garantías implícitas sobre mercadibilidad y aptitud para un propósito determinado. Más aún, QOHOR no garantiza que este sitio o el servidor que lo hace disponible estén libres de "virus de computadoras" por lo que el uso del sitio lo hacen los usuarios bajo su propia responsabilidad y riesgo.</p>
      <p>QOHOR no tiene obligación alguna de actualizar la Plataforma o la información contenida en él por lo que QOHOR no será responsable de la no actualización de dicha información. Adicionalmente, QOHOR tampoco es responsable por el uso, por parte del Usuario, de otros sitios en Internet que pudiera acceder a través de ligas contenidas en este sitio. Estas ligas y otros recursos a los que se hace referencia en este sitio se proveen únicamente como servicio a los usuarios de la "World Wide Web" y su inclusión en nuestro sitio no constituye un endoso de, o afiliación a QOHOR.</p>

      <h2>20. Revelación de información. </h2>
      <p>El Usuario acepta que QOHOR está obligado por la regulación aplicable a revelar su información y las operaciones que realice en la Plataforma o con los Servicios al regulador financiero. </p>

      <h2>III. Servicios. </h2>
      <h2>21. Declaraciones del Usuario.</h2>
      <p>Para efectos de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita el Usuario ratifica que:</p>
      <div class="p_margin_left">
        <p>a.	Los datos proporcionados a QOHOR son verídicos y correctos. </p>
        <p>b.	Cuando exista un Beneficiario Controlador diferente al Usuario, así lo manifestará a QOHOR; y,</p>
        <p>c.	Cuenta con los recursos económicos suficientes y necesarios para hacer frente a las obligaciones a su cargo establecidas en el Contrato, recursos que han sido y serán obtenidos exclusivamente de fuentes lícitas.</p>
      </div>

      <h2>22. Uso de los Servicios.</h2>
      <p>Sujeto a los Términos y Condiciones, QOHOR provee la Plataforma al Usuario para que, en el contexto de la celebración de un contrato de crédito con cargo a la nómina o la celebración de un contrato de servicio de adelanto de nómina, sujetos a los términos y condiciones establecidos en cualquiera de los contratos mencionados y en el Contrato celebrado con el empleador del Usuario, QOHOR otorgue un crédito simple  con cargo a la nómina o un servicio de adelanto de nómina al Usuario por un importe especificado por QOHOR en los contratos celebrados con el Usuario, cantidad que no comprende intereses, comisiones, impuestos, ni gastos que debe cubrir el Usuario.</p>
      <p>El Usuario, en este acto, declara unilateralmente su aceptación como obligado solidario (“Obligado Solidario”) de su empleador   para efectos del pago del préstamo correspondiente en virtud del Contrato de Comisión Mercantil sin representación (el “Contrato de Adelanto”) que su empleador celebró con TiFi como condición previa para el uso, por parte del Usuario, del beneficio “Adelanto de Nómina” proveído por TiFI. </p>
      <p>Para acceder a los Servicios, los Usuarios deberán registrarse proporcionando a QOHOR el nivel de información que éste le requiera. Los Servicios, pueden incluir servicios de mensajería, servicios de chat, blogs, entre otros foros y servicios disponibles.</p>
      <p>QOHOR no provee la emisión, administración, redención y transmisión de fondos de pago electrónico, ni la posibilidad de transaccionar con divisas, tampoco provee asesoría en inversiones o asesoría financiera y, por tanto, no será responsable del uso o interpretación de la información establecida en la Plataforma o por los Servicios o por la comunicación por otros medios.  </p>
      <p>Nada de lo pactado en el Contrato, ni el acceso o uso de los Servicios podrá interpretarse como: (i) una inversión en Valores, ni emisión de Valores de ninguna especie. Por lo tanto, no implica una oferta de Valores, ni privada ni pública; (ii) una intermediación de Valores, ni administración de cartera de Valores, ni toma decisiones en nombre de terceros. QOHOR no es asesor en inversiones. El Usuario deberá realizar sus propios análisis sobre la pertinencia o no de participar en los Servicios de QOHOR, QOHOR sugiere que obtenga su propia asesoría legal, fiscal y financiera; (iii) un fondo de inversión, ni una administración de fondos de inversión o realización de actividades descrita en la Ley de Fondos de Inversión. QOHOR no presta servicios de intermediación financiera, ni captará recursos del Inversionista. QOHOR no realizará, ni está autorizado a realizar, ninguna de las actividades a que se refiere la Ley de Instituciones de Crédito; y (iv) actividades auxiliares del crédito, incluyendo la realización habitual y profesional de operaciones de crédito, arrendamiento financiero y factoraje financiero. QOHOR tampoco realiza actividades restringidas de las descritas en la Ley para Regular las Instituciones de Tecnología Financiera.</p>

      <h2>23. Registro del Usuario.</h2>
      <p>El registro, acceso y uso de los Servicios por cada Usuario deberá ser en todo momento personal, por lo que los derechos y obligaciones que tiene al amparo del Contrato son únicos e intransferibles. </p>

      <h2>24. Responsabilidad del Usuario. </h2>
      <p>En todo momento el Usuario se obliga a defender e indemnizar a QOHOR, a sus directores, funcionarios, empleados y agentes, y a sacarlos en paz y a salvo de cualquier demanda, responsabilidad, costos y gastos, de cualquier naturaleza, incluyendo honorarios de abogados, en que incurriera como resultado del uso indebido de los Servicios, su incumplimiento o violación del Contrato o cualquier violación, pérdida o costo a cargo de QOHOR relacionado con:</p>
      <div class="p_margin_left">
        <p> -	El acceso del Usuario a los Servicios</p>
        <p> -	Violación de los presentes Términos y Condiciones</p>
        <p> -	Violación o infracción relacionada con propiedad intelectual, privacidad o derechos publicitarios de QOHOR. </p>
        <p> -	Cualquier pérdida, entrega indebida, apropiación indebida, robo o cualquier cuestión irremediable relacionada con los fondos de la Cuenta del Usuario.</p>
        <p> -	Negligencia o actos culpables u omisión (incluyendo imprudencia o actuación dolosa) por el Usuario. </p>
      </div>

      <h2>25. Restricciones de acceso.</h2>
      <p>El uso y acceso a la Plataforma y los Servicios no está permitido desde aquellas jurisdicciones en las cuales: (i) No esté permitida la realización de las operaciones mencionadas en los Servicios; y (ii) QOHOR determine que existe un posible riesgo o un riesgo determinado en la realización de las operaciones mencionadas en los Servicios.</p>
      <p>Por otro lado, es posible que debido al lugar de conexión del Usuario, la totalidad o parcialidad de los Servicios, no esté disponible. </p>
      <p>En el contexto de lo anterior, QOHOR no se hace responsable de los actos u omisiones realizados por el Usuario que contraviene lo especificado en los presentes Términos y Condiciones. Por tanto, QOHOR se reserva el derecho de suspender o evitar la provisión de los Servicios a dichos Usuarios. </p>
      <p>Al accesar a los Servicios, el Usuario acuerda que QOHOR tendrá el derecho a investigar cualquier violación a los presentes Términos y Condiciones y a determinar de manera unilateral su violación y tomar acciones respecto a la provisión de los Servicios, sin necesidad de tu consentimiento y sin notificación previa. Entre las acciones que puede tomar QOHOR se mencionan, de manera enunciativa más no limitativa, las siguientes: (i) bloquear y cerrar órdenes de transferencia; (ii) congelar la cuenta del Usuario; (iii) reportar incidentes a las autoridades competentes; (iv) notificar sobre las violaciones alegadas y las acciones que se tomaron; y (v) borrar cualquier información publicada por el Usuario que haya violado los presentes Términos y Condiciones. </p>
      <p>En el uso de los Servicios, el Usuario tendrá estrictamente prohibido: </p>
      <div class="p_margin_left">
        <p>a.	difundir información falsa o engañosa o que induzca al error; </p>
        <p>b.	restringir o inhibir a cualquier otro usuario de usar y disfrutar los Servicios; </p>
        <p>c.	Utilizar los Servicios para realizar cualquier conducta que tienda a manipular el mercado</p>
        <p>d.	ser fraudulento, ilegal, amenazante, abusivo, hostigante, calumnioso, difamatorio, obsceno, vulgar, ofensivo, pornográfico, profano, sexualmente explícito o indecente; </p>
        <p>e.	generar o alentar conductas que pudieran constituir una ofensa criminal, dar lugar a responsabilidad civil o de otro modo violar cualquier ley local, estatal, nacional o internacional;</p>
        <p>f.	violar, plagiar o infringir los derechos de terceros incluyendo, sin limitación, derechos de autor, marcas comerciales, secretos comerciales, confidencialidad, contratos, patentes, derechos de privacidad o publicidad o cualquier otro derecho de propiedad; </p>
        <p>g.	diseminar virus, elementos de espionaje u otro componente dañino; </p>
        <p>h.	emitir enlaces fijos, publicidad, cartas de cadenas o esquemas de pirámides de cualquier tipo;</p>
        <p>i.	retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier parte de los Servicios; </p>
        <p>j.	reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra forma los Servicios, excepto como se permita expresamente por QOHOR;</p>
        <p>k.	descompilar, realizar ingeniería inversa o desmontar los Servicios, excepto como se permita por la ley aplicable; </p>
        <p>l.	enlazar, reflejar o enmarcar cualquier parte de los Servicios; </p>
        <p>m.	causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de los Servicios o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto de los Servicios; </p>
        <p>n.	intentar obtener un acceso no autorizado o dañar cualquier aspecto de los Servicios o sus sistemas o redes relacionados.</p>
      </div>

      <h2>26. Servicios y operaciones.</h2>
      <p>Las operaciones realizadas por el Usuario mediante el uso de la Plataforma y los Servicios son finales y no reversibles. QOHOR no será responsable por pérdidas inducidas por el ingreso de información incorrecta. Las operaciones del Usuario podrán ser canceladas en cualquier momento por QOHOR sin que esté de garantía alguna de que serán ejecutadas. </p>

      <h2>27. Enlaces.</h2>
      <p>Para fines de calidad en los Servicios, QOHOR puede permitir uno o más enlaces a otros sitios web de vez en cuando. Las páginas de Internet a las que se pueda acceder a este sitio web no están bajo el control de QOHOR por lo que no asume ninguna responsabilidad por el contenido de cualquier página web de Internet vinculada a los Servicios, o por cualquier daño potencial que surja de o en conexión con el uso de dichos enlaces.</p>
      <p>Además, la existencia de un vínculo entre los Servicios y cualquier otro sitio web no constituye ni debe ser entendida como un respaldo por parte de QOHOR respecto del propietario o titular de la página web de Internet vinculada, ni una aprobación por parte de QOHOR en favor del dueño o propietario del sitio web vinculado.</p>

      <h2>28. Datos Personales y Cookies. </h2>
      <p>Los Datos Personales recabados por QOHOR de los Usuarios, serán tratados de conformidad con lo establecido en el Aviso de Privacidad y Política de Cookies.</p>
      <h2>IV. Servicios de atención al Usuario</h2>
      <p>El Usuario se puede comunicar con QOHOR haciendo uso de la Plataforma. La Plataforma indicará las redes oficiales de QOHOR. </p>
      <h2>V1. ACLARACIONES</h2>
      <p>Para mayor información, dudas o aclaraciones deberá comunicarse por los siguiente medio: correo electrónico: contacto@tifi.com.mx</p>
      <p>Última revisión: Agosto, 2021 </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang='scss'>
.terminos_container {
  width: 100%;
  .content {
    padding: 60px 0px 45px;
    width: 86%;
    margin: 0 auto;
    // padding: 60px 0 50px 0;
    h1 {
      word-break: break-word;
      color: var(--primary-blue);
    }
    h2 {
      word-break: break-word;
      margin-top: 20px;
      font-family: 'Poppins ExtraBold 800', sans-serif;
      color: var(--primary-blue);
      font-size: 22px;
      font-weight: bold;
    }
    p {
      word-break: break-word;
      font-size: 22px;
      color: var(--primary-blue);
      font-family: 'Poppins SemiBold 600 Italic', sans-serif;
      padding-left: 0;
      padding-right: 0;
      line-height: 30px;
    }
    .p_margin_left {
      div {
        font-size: 22px;
        color: var(--primary-blue);
        font-family: 'Poppins SemiBold 600 Italic', sans-serif;
        padding-left: 0;
        padding-right: 0;
        line-height: 45px;
      }
      p {
        margin-left: 30px;
      }
    }
  }

}

@media screen and (max-width: 1246px) {
  .terminos_container {
    width: 100%;
    .content {
      padding: 60px 0 45px;
      padding-left: 60px;
      width: 86%;
      margin: 0 auto;
      h1 {
        font-size: 50px;
        margin: 40px 0;
      }
      h2 {
        font-size: 35px !important;
      }
      p {
        font-size: 35px;
        line-height: 50px;
      }
    }
  }
}
</style>
